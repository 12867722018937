<template>
  <div>
    <div class="modal fade" id="modal-escaner-pago">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Datos de Pago</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
              id="close-modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-if="bonoViaje && bonoViaje.asignacion">
            <div class="card-body bg-gray rounded shadow">
              <div class="row">
                <div class="col-md-4"><label>Bono Id</label></div>
                <div class="col-md-8">
                  {{ bonoViaje.id }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-4"><label>Guía / Ticket</label></div>
                <div
                  class="col-md-8"
                  v-if="
                    bonoViaje.asignacion.guia !== null &&
                      bonoViaje.asignacion.guia !== undefined
                  "
                >
                  {{
                    bonoViaje.asignacion
                      ? bonoViaje.asignacion.guia.numero
                      : ""
                  }}-{{
                    bonoViaje.asignacion
                      ? bonoViaje.asignacion.guia.digito_verificacion
                      : ""
                  }}
                </div>
                <div
                  class="col-md-8"
                  v-if="
                    bonoViaje.asignacion.ticket !== null &&
                      bonoViaje.asignacion.ticket !== undefined
                  "
                >
                  {{
                    bonoViaje.asignacion
                      ? bonoViaje.asignacion.ticket.numero_ticket
                      : ""
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-4"><label>Vehículo</label></div>
                <div class="col-md-8">
                  {{
                    bonoViaje.asignacion
                      ? bonoViaje.asignacion.vehiculo.placa
                      : ""
                  }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-4"><label>Conductor</label></div>
                <div class="col-md-8">
                  {{
                    bonoViaje.asignacion
                      ? bonoViaje.asignacion.conductor.numero_documento
                      : ""
                  }},
                  {{
                    bonoViaje.asignacion
                      ? bonoViaje.asignacion.conductor.nombres
                      : ""
                  }}
                  {{
                    bonoViaje.asignacion
                      ? bonoViaje.asignacion.conductor.apellidos
                      : ""
                  }}
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label for="fecha_pago">Fecha Pago</label>
                  <input
                    type="date"
                    class="form-control form-control-sm shadow-sm"
                    id="fecha_pago"
                    placeholder="Fecha Emisión"
                    v-model="formDoc.fecha_pago"
                    :disabled="escaner_acta"
                    :class="
                      formDoc.fecha_pago
                        ? 'border border-success '
                        : 'border border-danger'
                    "
                  />
                </div>
              </div>
            </div>
            <div v-if="escaner_acta">
              <div class="row">
                <div class="col-md-12">
                  <!-- small box -->
                  <div class="small-box bg-info">
                    <div class="inner">
                      <p>
                        {{
                          bonoViaje.asignacion_type == "App\\Conductor"
                            ? "Conductor:"
                            : "Viaje:"
                        }}
                        {{
                          bonoViaje.asignacion_type == "App\\Conductor"
                            ? bonoViaje.asignacion.nombres +
                              " " +
                              bonoViaje.asignacion.apellidos
                            : bonoViaje.asignacion_id
                        }}
                      </p>
                      <p v-if="bonoViaje.asignacion_type == 'App\\Viaje'">
                        Guía: {{ bonoViaje.asignacion.guia.numero }} -{{
                          bonoViaje.asignacion.guia.digito_verificacion
                        }}
                      </p>
                      <p>
                        {{
                          bonoViaje.asignacion_type == "App\\Conductor"
                            ? "Doc Conductor:"
                            : "Placa:"
                        }}
                        {{
                          bonoViaje.asignacion_type == "App\\Conductor"
                            ? bonoViaje.asignacion.nombres +
                              " " +
                              bonoViaje.asignacion.apellidos
                            : bonoViaje.asignacion.vehiculo.placa
                        }}
                      </p>
                    </div>
                    <div
                      class="icon"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.gestionBonosViajes.deleteSoportePago'
                        )
                      "
                    >
                      <i
                        class="ion ion-trash-a text-danger"
                        @click="destroyActa()"
                      ></i>
                    </div>
                    <a
                      class="small-box-footer"
                      :href="escaner_acta"
                      target="_blank"
                      >Imprimir Acta
                      <button type="button" class="btn btn-sm bg-danger">
                        <i class="fas fa-file-pdf text-white"></i>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-lg-12">
                <input
                  type="file"
                  id="link_pdf"
                  ref="link_pdf"
                  class="col-lg-12 shadow-sm"
                  :class="
                    file_pdf
                      ? 'btn btn-sm border border-success alert-success'
                      : 'btn btn-sm border border-warning alert-warning'
                  "
                  accept=".pdf, .PDF"
                  @change="elegirDirectorio()"
                />
              </div>
              <div class="progress mt-3 col-lg-12" v-if="loading">
                <div
                  class="progress-bar progress-bar-striped progress-bar-animated bg-success"
                  :class="
                    progress < 50
                      ? 'bg-danger'
                      : progress > 50 && progress < 98
                      ? 'bg-warning'
                      : 'bg-success'
                  "
                  role="progressbar"
                  :style="'width:' + progress + '%'"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ progress }}%
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-footer justify-content-between"
            v-if="
              !$v.formDoc.$invalid &&
                !$v.file_pdf.$invalid &&
                $store.getters.can(
                  'hidrocarburos.gestionBonosViajes.saveSoportePago'
                )
            "
          >
            <button
              type="button"
              class="btn bg-gradient-primary"
              @click="saveFiles()"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
export default {
  name: "GestionBonoEscanerPago",
  components: {},
  data() {
    return {
      bonoViaje: {},
      formDoc: {},
      cargar: null,
      progress: 40,
      loading: false,
      imagenCargada: null,
      escaner_acta: null,
      file_pdf: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations() {
    return {
      formDoc: {
        fecha_pago: {
          required,
        },
      },

      file_pdf: {
        required,
      },
    };
  },

  methods: {
    llenar_modal(bonoV) {
      this.limpiarModal();
      this.bonoViaje = bonoV;
      this.escaner_acta = bonoV.link_soporte
        ? this.uri_docs + bonoV.link_soporte
        : null;
    },

    saveFiles() {
      var self = this;
      self.loading = true;
      let formData = new FormData();
      if (self.file_pdf) {
        formData.append("link_pdf", self.file_pdf);
        formData.append("bono_viaje_id", this.bonoViaje.id);
        formData.append(
          "conductor_id",
          this.bonoViaje.asignacion_type == "App\\Viaje"
            ? this.bonoViaje.asignacion.conductor_id
            : this.bonoViaje.asignacion_id
        );
        axios
          .post("api/hidrocarburos/bonoViaje/saveActa", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              self.progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            },
          })
          .then((response) => {
            self.progress == 100
              ? (self.loading = false)
              : (self.loading = true);
            this.$parent.getIndex();
            this.$refs.closeModal.click();
            self.$swal({
              icon: "success",
              title:
                "El Acta " + self.file_pdf.name + " se cargó correctamente",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            self.file_pdf = null;
            self.$refs.link_pdf.files = null;
            let inputPdf = document.getElementById("link_pdf");
            inputPdf.value = "";
          })
          .catch(function(error) {
            self.loading = false;
            self.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      } else {
        self.loading = false;
        self.$swal({
          icon: "error",
          title: "Para guardar debe seleccionar un archivo",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    destroyActa() {
      if (
        this.$store.getters.can("hidrocarburos.gestionBonosViajes.deleteActa")
      ) {
        let data = {
          id: this.bonoViaje.id,
          link: this.bonoViaje.link_soporte,
        };
        this.$swal({
          title: "Esta seguro de eliminar este Archivo?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Eliminar!",
        }).then((result) => {
          if (result.value) {
            this.$parent.cargando = true;
            axios
              .post("/api/hidrocarburos/bonoViaje/descartarActa", data)
              .then(() => {
                this.formDoc.fecha_pago = null;
                this.$parent.getIndex();
                this.limpiarModal();
                this.$swal({
                  icon: "success",
                  title: "El Archivo se elimino correctamente...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              })
              .catch(function(error) {
                this.$swal({
                  icon: "error",
                  title: "Ocurrio un error" + error,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.$parent.cargando = false;
              });
          }
        });
      }
    },

    elegirDirectorio() {
      this.file_pdf = this.$refs.link_pdf.files[0];
    },

    limpiarModal() {
      this.file_pdf = null;
      this.escaner_acta = null;
    },
  },
};
</script>
