var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade",attrs:{"id":"modal-escaner-pago"}},[_c('div',{staticClass:"modal-dialog modal-md"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Datos de Pago")]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),(_vm.bonoViaje && _vm.bonoViaje.asignacion)?_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"card-body bg-gray rounded shadow"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-md-8"},[_vm._v(" "+_vm._s(_vm.bonoViaje.id)+" ")])]),_c('div',{staticClass:"row"},[_vm._m(1),(
                  _vm.bonoViaje.asignacion.guia !== null &&
                    _vm.bonoViaje.asignacion.guia !== undefined
                )?_c('div',{staticClass:"col-md-8"},[_vm._v(" "+_vm._s(_vm.bonoViaje.asignacion ? _vm.bonoViaje.asignacion.guia.numero : "")+"-"+_vm._s(_vm.bonoViaje.asignacion ? _vm.bonoViaje.asignacion.guia.digito_verificacion : "")+" ")]):_vm._e(),(
                  _vm.bonoViaje.asignacion.ticket !== null &&
                    _vm.bonoViaje.asignacion.ticket !== undefined
                )?_c('div',{staticClass:"col-md-8"},[_vm._v(" "+_vm._s(_vm.bonoViaje.asignacion ? _vm.bonoViaje.asignacion.ticket.numero_ticket : "")+" ")]):_vm._e()]),_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-md-8"},[_vm._v(" "+_vm._s(_vm.bonoViaje.asignacion ? _vm.bonoViaje.asignacion.vehiculo.placa : "")+" ")])]),_c('div',{staticClass:"row"},[_vm._m(3),_c('div',{staticClass:"col-md-8"},[_vm._v(" "+_vm._s(_vm.bonoViaje.asignacion ? _vm.bonoViaje.asignacion.conductor.numero_documento : "")+", "+_vm._s(_vm.bonoViaje.asignacion ? _vm.bonoViaje.asignacion.conductor.nombres : "")+" "+_vm._s(_vm.bonoViaje.asignacion ? _vm.bonoViaje.asignacion.conductor.apellidos : "")+" ")])])]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"fecha_pago"}},[_vm._v("Fecha Pago")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formDoc.fecha_pago),expression:"formDoc.fecha_pago"}],staticClass:"form-control form-control-sm shadow-sm",class:_vm.formDoc.fecha_pago
                      ? 'border border-success '
                      : 'border border-danger',attrs:{"type":"date","id":"fecha_pago","placeholder":"Fecha Emisión","disabled":_vm.escaner_acta},domProps:{"value":(_vm.formDoc.fecha_pago)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formDoc, "fecha_pago", $event.target.value)}}})])])]),(_vm.escaner_acta)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"small-box bg-info"},[_c('div',{staticClass:"inner"},[_c('p',[_vm._v(" "+_vm._s(_vm.bonoViaje.asignacion_type == "App\\Conductor" ? "Conductor:" : "Viaje:")+" "+_vm._s(_vm.bonoViaje.asignacion_type == "App\\Conductor" ? _vm.bonoViaje.asignacion.nombres + " " + _vm.bonoViaje.asignacion.apellidos : _vm.bonoViaje.asignacion_id)+" ")]),(_vm.bonoViaje.asignacion_type == 'App\\Viaje')?_c('p',[_vm._v(" Guía: "+_vm._s(_vm.bonoViaje.asignacion.guia.numero)+" -"+_vm._s(_vm.bonoViaje.asignacion.guia.digito_verificacion)+" ")]):_vm._e(),_c('p',[_vm._v(" "+_vm._s(_vm.bonoViaje.asignacion_type == "App\\Conductor" ? "Doc Conductor:" : "Placa:")+" "+_vm._s(_vm.bonoViaje.asignacion_type == "App\\Conductor" ? _vm.bonoViaje.asignacion.nombres + " " + _vm.bonoViaje.asignacion.apellidos : _vm.bonoViaje.asignacion.vehiculo.placa)+" ")])]),(
                      _vm.$store.getters.can(
                        'hidrocarburos.gestionBonosViajes.deleteSoportePago'
                      )
                    )?_c('div',{staticClass:"icon"},[_c('i',{staticClass:"ion ion-trash-a text-danger",on:{"click":function($event){return _vm.destroyActa()}}})]):_vm._e(),_c('a',{staticClass:"small-box-footer",attrs:{"href":_vm.escaner_acta,"target":"_blank"}},[_vm._v("Imprimir Acta "),_vm._m(4)])])])])]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('input',{ref:"link_pdf",staticClass:"col-lg-12 shadow-sm",class:_vm.file_pdf
                    ? 'btn btn-sm border border-success alert-success'
                    : 'btn btn-sm border border-warning alert-warning',attrs:{"type":"file","id":"link_pdf","accept":".pdf, .PDF"},on:{"change":function($event){return _vm.elegirDirectorio()}}})]),(_vm.loading)?_c('div',{staticClass:"progress mt-3 col-lg-12"},[_c('div',{staticClass:"progress-bar progress-bar-striped progress-bar-animated bg-success",class:_vm.progress < 50
                    ? 'bg-danger'
                    : _vm.progress > 50 && _vm.progress < 98
                    ? 'bg-warning'
                    : 'bg-success',style:('width:' + _vm.progress + '%'),attrs:{"role":"progressbar","aria-valuenow":"25","aria-valuemin":"0","aria-valuemax":"100"}},[_vm._v(" "+_vm._s(_vm.progress)+"% ")])]):_vm._e()])]):_vm._e(),(
            !_vm.$v.formDoc.$invalid &&
              !_vm.$v.file_pdf.$invalid &&
              _vm.$store.getters.can(
                'hidrocarburos.gestionBonosViajes.saveSoportePago'
              )
          )?_c('div',{staticClass:"modal-footer justify-content-between"},[_c('button',{staticClass:"btn bg-gradient-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveFiles()}}},[_vm._v(" Guardar ")])]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4"},[_c('label',[_vm._v("Bono Id")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4"},[_c('label',[_vm._v("Guía / Ticket")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4"},[_c('label',[_vm._v("Vehículo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4"},[_c('label',[_vm._v("Conductor")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-sm bg-danger",attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-file-pdf text-white"})])
}]

export { render, staticRenderFns }